import React from "react";
import { Link } from "react-router-dom";

export default function Home(props) {
  return (
    <>
      {/* Update  */}

      <div className="main ml-40 flex justify-center  max-md:ml-0">
        <div className="main-glass bg-whitelite border-whitegood backdrop-blur shadow h-auto w-113">
          <div className="mt-12 center-img flex justify-center flex-wrap">
            <div className="img mr-6 max-md:h-64 max-md:mb-10 max-md:mr-0">
              <Link to={props.link1}>
                <img
                  src={props.img1}
                  alt={props.name1}
                  className="h-100.1 w-auto rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 max-md:h-56 max-md:w-auto max-md:mr-2 "
                />
                <div className="title ">
                  <h2 className="text-white mt-2 text-2xl w-60 max-md:w-44 max-md:mt-1  ">
                    {props.name1}
                  </h2>
                </div>
              </Link>
            </div>
            {/* 2 row  */}
            <div className="img mr-6 max-md:h-64 max-md:mr-0">
              <Link to={props.link2}>
                <img
                  src={props.img2}
                  alt={props.name2}
                  className="h-100.1 w-auto rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 max-md:h-56 max-md:w-auto"
                />
                <div className="title ">
                  <h2 className="text-white mt-2 text-2xl w-60 max-md:w-44 max-md:mt-1">
                    {props.name2}
                  </h2>
                </div>
              </Link>
            </div>
            {/* 3 row */}
            <div className="img mr-6 max-md:h-64 max-md:mr-0 max-md:mt-16 max-md:mb-16">
              <Link to={props.link3}>
                <img
                  src={props.img3}
                  alt={props.name3}
                  className="h-100.1 w-auto rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 max-md:h-56 max-md:w-auto max-md:mr-2"
                />
                <div className="title ">
                  <h2 className="text-white mt-2 text-2xl w-60 max-md:w-44 max-md:mt-1 max-md:">
                    {props.name3}
                  </h2>
                </div>
              </Link>
            </div>
            {/* 4 row  */}
            <div className="img max-md:mt-16 max-md:mb-16">
              <Link to={props.link4}>
                <img
                  src={props.img4}
                  alt={props.name4}
                  className="h-100.1 w-auto rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 max-md:h-56 max-md:w-auto"
                />
                <div className="title ">
                  <h2 className="text-white mt-2 text-2xl w-60 max-md:w-44 max-md:mt-1">
                    {props.name4}
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
