import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Quality() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/pTxZTsvx/anime-quality-assurance-in-another-world-visuel-2.avif"
        name="Quality Assurance in Another World"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Quality Assurance in Another World"
        s="S1-E1"
        link="https://drive.google.com/open?id=1rcELF2GgCcSWk64PD0hS93FLljWO7rUw&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Quality Assurance in Another World"
        s="S1-E2"
        link="https://drive.google.com/open?id=1z_686GPj9zY2IdNZlm4sOWY7K4yAsY_F&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Quality Assurance in Another World"
        s="S1-E3"
        link="https://drive.google.com/open?id=1oBzfTeTrAXl3f1U3bGE2aFo21J-C3eis&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Quality Assurance in Another World"
        s="S1-E4"
        link="https://drive.google.com/open?id=14d2d58U7eayKMpNEiSYu8AfCmWTjJQJg&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Quality Assurance in Another World"
        s="S1-E5"
        link="https://drive.google.com/open?id=1fFw0YmaaRfNzRqwqfOEdSKcHNECpYJ5y&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Quality Assurance in Another World"
        s="S1-E6"
        link="https://drive.google.com/open?id=1dl4LmpQ18uJ87ag2kS5hpO9CehSnmA6w&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Quality Assurance in Another World"
        s="S1-E7"
        link="https://drive.google.com/open?id=1NReYJ9JpTsQok9Ia-U6138dzntnS-NG-&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Quality Assurance in Another World"
        s="S1-E8"
        link="https://drive.google.com/open?id=1yDiAmUCtBUcInR9g5z3slaEgRUgwabCb&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Quality Assurance in Another World"
        s="S1-E9"
        link="https://drive.google.com/open?id=1RyrFR9rLNRmf6NXSdXOyhS3qn9B2leVa&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Quality Assurance in Another World"
        s="S1-E10"
        link="https://drive.google.com/open?id=132IdUyrBW94Irt5v9h8UJp5gIfOeNNOS&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Quality Assurance in Another World"
        s="S1-E11"
        link="https://drive.google.com/open?id=1-sAIuTjsOTAFOh1HgThqMzFQERkL8G5G&usp=drive_copy"
      />
      {/* <Ep ep="S1-E12 Quality Assurance in Another World" s="S1-E12" link=""/>  */}
      <Animeover />
      <Footer />
    </>
  );
}
