import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Fairytail() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/Mpm0LyRF/4453c15bb8b93115a72886112afbe087247318588beaf0e46539aa55a9bb28a7-RI-TTW.jpg"
        name="Fairy Tail: 100 Years Quest"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Fairy Tail: 100 Years Quest "
        s="S1-E1"
        link="https://drive.google.com/file/d/17o1R78UHbUviF99hobjWnPdNLzO7q0vy/view?usp=drive_link"
      />
      <Ep
        ep="S1-E2 Fairy Tail: 100 Years Quest "
        s="S1-E2"
        link="https://drive.google.com/open?id=1iWHy7q-wBqFp-slL8ybWRLcGLCkIWOIX&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Fairy Tail: 100 Years Quest  "
        s="S1-E3"
        link="https://drive.google.com/file/d/1slUjGby7vxDcERjOIH40gXYdVM4tsFNb/view?usp=sharing"
      />
      {/* <Ep ep="S1-E4 Fairy Tail: 100 Years Quest " s="S1-E4" link=""/>
      <Ep ep="S1-E5 Fairy Tail: 100 Years Quest " s="S1-E5" link=""/>
      <Ep ep="S1-E6 Fairy Tail: 100 Years Quest " s="S1-E6" link=""/>
      <Ep ep="S1-E7 Fairy Tail: 100 Years Quest " s="S1-E7" link=""/>
      <Ep ep="S1-E8 Fairy Tail: 100 Years Quest " s="S1-E8" link=""/>
      <Ep ep="S1-E9 Fairy Tail: 100 Years Quest " s="S1-E9" link=""/>
      <Ep ep="S1-E10 Fairy Tail: 100 Years Quest " s="S1-E10" link=""/>
      <Ep ep="S1-E11 Fairy Tail: 100 Years Quest " s="S1-E11" link=""/>
      <Ep ep="S1-E12 Fairy Tail: 100 Years Quest " s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  );
}
