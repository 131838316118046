import React from "react";
export default function Complete() {
  return (
    <>
      <div className="ml-40 max-md:ml-0 flex justify-center">
        <div className="ep-over flex items-center justify-center bg-[rgb(11,11,65)] h-20 w-[1276px] mt-1 rounded-b-lg border-none">
          <p>
            <b className="text-white font-bold text-lg">
              {" "}
              The Anime is Completes 😊
            </b>
          </p>
        </div>
      </div>
    </>
  );
}
