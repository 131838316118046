import React from "react";

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Blackrock() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/C15p3ffG/images-1.jpg"
        name="Black Rock Shooter: Dawn Fall"
        s="1"
        e="12"
      />
      <Ep
        ep="S1-E1 Black Rock Shooter: Dawn Fall"
        s="S1-E1"
        link="https://drive.google.com/open?id=1vXXuUR5MXgpUMU4LT2aYiKGbHOJRPKzY&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Black Rock Shooter: Dawn Fall"
        s="S1-E2"
        link="https://drive.google.com/open?id=1XLxwsfdU_y8p9Bbw4ddT3N7w1F67GdVf&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Black Rock Shooter: Dawn Fall"
        s="S1-E3"
        link="https://drive.google.com/open?id=1J0RX8bumtGYQ8598802qiemESqKRf2Yw&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Black Rock Shooter: Dawn Fall"
        s="S1-E4"
        link="https://drive.google.com/open?id=1emRJTltyD5sCLdCjzhaAAkM4M6MRNFFe&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Black Rock Shooter: Dawn Fall"
        s="S1-E5"
        link="https://drive.google.com/open?id=1vAS7NaPCl6PdQXyPBbqphiyTyiKjzWBc&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Black Rock Shooter: Dawn Fall"
        s="S1-E6"
        link="https://drive.google.com/open?id=1nJrlabXgr9_-RV4slobhzRKDMV3da7rr&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Black Rock Shooter: Dawn Fall"
        s="S1-E7"
        link="https://drive.google.com/open?id=1JuraB3pYl2LcqE-j_6fi46B-pBhftjNZ&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Black Rock Shooter: Dawn Fall"
        s="S1-E8"
        link="https://drive.google.com/open?id=1FB1FicakCfE4_eAruU5Y8947idr8wwj8&usp=drive_copy"
      />
      <Ep ep="S1-E9 Black Rock Shooter: Dawn Fall" s="S1-E9" link="" />
      <Ep
        ep="S1-E10 Black Rock Shooter: Dawn Fall"
        s="S1-E10"
        link="https://drive.google.com/open?id=1u8XNy49X_vTY78AhGcb4rjD_i7xplVbi&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Black Rock Shooter: Dawn Fall"
        s="S1-E11"
        link="https://drive.google.com/open?id=1Norn2i9mB15Yhrs_hj6e7fRu2ULnRYHu&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Black Rock Shooter: Dawn Fall"
        s="S1-E12"
        link="https://drive.google.com/open?id=1P01dxdMJXAmU9O7CxeQD3lKHhacPPaNz&usp=drive_copy"
      />

      <Animeover />
      <Footer />
    </>
  );
}
