import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Thestrongestma() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/cJf7xb1X/maougun-KV-1452x2048.png"
        name="The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E1"
        link="https://drive.google.com/open?id=1zRf5ZuSYYzNUDqx0tTePLj_26Cgcpuq9&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E2"
        link="https://drive.google.com/open?id=1lp9hX7loXtNHXWJrKkqLyQfdmyDorQPT&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E3"
        link="https://drive.google.com/open?id=1oUeLAgneJ100vYD_KoLj341u3f3c5awO&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E4"
        link="https://drive.google.com/open?id=1GfcyI1_v9gAf7PbY9DxeugjZdLIg5XfM&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E5"
        link="https://drive.google.com/open?id=1g10WERpZkabF53by5L0uVuspVktC9ag0&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E6"
        link="https://drive.google.com/open?id=10YwJnCfBdCS7kHikZaEfWEeUyIPCy4oi&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E7"
        link="https://drive.google.com/open?id=1reIp7JYjf_Hq2m-REVfNMEUQUo8MAKja&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E8"
        link="https://drive.google.com/open?id=1gMgwgo68wl4itf3Nh5_O2DQaDSw_JwCk&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 The Strongest Magician in the Demon Lord’s Army Was a Human"
        s="S1-E9"
        link="https://drive.google.com/file/d/1gMgwgo68wl4itf3Nh5_O2DQaDSw_JwCk/view?usp=drive_link"
      />
      {/*<Ep ep="S1-E10 The Strongest Magician in the Demon Lord’s Army Was a Human" s="S1-E10" link=""/>
      <Ep ep="S1-E11 The Strongest Magician in the Demon Lord’s Army Was a Human" s="S1-E11" link=""/>
      <Ep ep="S1-E12 The Strongest Magician in the Demon Lord’s Army Was a Human" s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  );
}
