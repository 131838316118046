import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
import ScrollUpButton from "../components/ScrollUpButton";

export default function Bluelock() {
  return (
    <>
      <ScrollUpButton />
      <Main
        img="https://i.postimg.cc/YqsjCj7C/E85-Ab-Dw-Vg-AQV5-AV.jpg"
        name="BLUE LOCK"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 BLUE LOCK"
        s="S1-E1"
        link="https://drive.google.com/open?id=13PE0uaRMcy6d6UeP1MP0p8ud-C5bvmWj&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 BLUE LOCK"
        s="S1-E2"
        link="https://drive.google.com/open?id=1MvJgb-QNWj7-Ar_oRp4sRG2gyx-RH6y9&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 BLUE LOCK"
        s="S1-E3"
        link="https://drive.google.com/open?id=1ulkIYsEQKe_7JHre2Vtfw_W0pvyx_qBj&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 BLUE LOCK"
        s="S1-E4"
        link="https://drive.google.com/open?id=1xUt9qWBE__KzZRT7O9BedZDlh7BeWTOw&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 BLUE LOCK"
        s="S1-E5"
        link="https://drive.google.com/open?id=1YlQF_RulStzaZyxqI7rsEU5klPpA3fT6&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 BLUE LOCK"
        s="S1-E6"
        link="https://drive.google.com/open?id=1_PvtSr0ygjydp_32baa98-rPhhu0CBRO&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 BLUE LOCK"
        s="S1-E7"
        link="https://drive.google.com/open?id=17crQS4JLfLTrZLC1kJ9H9LJMxbCfaoOV&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 BLUE LOCK"
        s="S1-E8"
        link="https://drive.google.com/open?id=17crQS4JLfLTrZLC1kJ9H9LJMxbCfaoOV&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 BLUE LOCK"
        s="S1-E9"
        link="https://drive.google.com/open?id=1FDd70mv7gR33rsG4CIlq-Ks4Uqp_9F62&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 BLUE LOCK"
        s="S1-E10"
        link="https://drive.google.com/open?id=1B5OVIQzX7mVcW2gzQxxJfugZ_VtaFbva&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 BLUE LOCK"
        s="S1-E11"
        link="https://drive.google.com/open?id=11BZ1s_mv7FCHHb5E2ievXXgAt3e5tgYh&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 BLUE LOCK"
        s="S1-E12"
        link="https://drive.google.com/open?id=1hFIb6nj2H2GK5e50tHzdzh-yB6E2DNUR&usp=drive_copy"
      />
      <Complete />
      <Footer />
    </>
  );
}
