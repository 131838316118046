import React from "react";
import { Link } from "react-router-dom";

export default function Ban(props) {
  return (
    <>
      <div className="relative z-10 flex justify-center max-md:hidden  ml-40">
        <div className="flex justify-center items-center h-96 w-100 bg-whitelite backdrop-blur">
          <Link to={props.link}>
            <img
              className="h-80 w-auto"
              src={props.ban}
              alt="Anime images of roco toons "
            />
          </Link>
        </div>
      </div>
    </>
  );
}
