import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Spy() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://image.tmdb.org/t/p/original/f4PmYfliarqfMeFNdnK6NZ6AU5i.jpg"
        name="SpyxFamily"
        s="2"
        e="12"
      />

      <Ep
        ep="S1-E1 Spy x Family"
        s="S1-E1"
        link="https://drive.google.com/open?id=1mFQo2RWWNS8sPanFpNg-XKrxbnC7_Zbo&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Spy x Family"
        s="S1-E2"
        link="https://drive.google.com/open?id=1sRJrIBCIFoN8q5mlpErfOMhWNQP1znBA&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Spy x Family"
        s="S1-E3"
        link="https://drive.google.com/open?id=1GANBaotsnrleqXSsA7gvv6fG6Zxrmojv&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Spy x Family"
        s="S1-E4"
        link="https://drive.google.com/open?id=1ZZkHRWdgpXRguCXUoGzxai3jjTpSqrW-&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Spy x Family"
        s="S1-E5"
        link="https://drive.google.com/open?id=1SLGw6Moj2HnNbO9pFgjmZwdagqzytQBx&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Spy x Family"
        s="S1-E6"
        link="https://drive.google.com/open?id=1uhNi_czgVgEJl_bPmyhWuPgcV63-4tK_&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Spy x Family"
        s="S1-E7"
        link="https://drive.google.com/open?id=1MTvUSrdEWKQ0dSgxeIdwuMxD0DgzyP0Q&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Spy x Family"
        s="S1-E8"
        link="https://drive.google.com/open?id=1Nd9M_axgySOAUeBVaxsc61uVw1JZ8Vas&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Spy x Family"
        s="S1-E9"
        link="https://drive.google.com/open?id=10ZOomIUkmBpAIRSzLT6ka53ZBncf8IEo&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Spy x Family"
        s="S1-E10"
        link="https://drive.google.com/open?id=1Fy_xjDOFbo298oYv9yz6dYoiCXkctK7R&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Spy x Family"
        s="S1-E11"
        link="https://drive.google.com/open?id=1fVom72NdAn_5_O0Hkrw-bidJk2NyAHtY&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Spy x Family"
        s="S1-E12"
        link="https://drive.google.com/open?id=1nqPWUQcO1eCPaaHW5ol61wVH59rRogsV&usp=drive_copy"
      />
      <Animeover />
      <Footer />
    </>
  );
}
