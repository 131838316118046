import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Kaijuno8() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/q7RThxGf/c1-T8-UHw-Caq6-Je-QYgt4-U8-ERPGox-N.jpg"
        name="Kaiju No. 8"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Kaiju No. 8"
        s="S1-E1"
        link="https://drive.google.com/open?id=1JDjXJ2ot-MzqkV2islLAbNBCoAVygoxt&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Kaiju No. 8"
        s="S1-E2"
        link="https://drive.google.com/open?id=1ZJqVjj1xQ5j5CublfmMUAo83Px0dRegd&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Kaiju No. 8"
        s="S1-E3"
        link="https://drive.google.com/open?id=1M8Omuh1VrOzLNpR8diD_NB7_WEGNGQXM&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Kaiju No. 8"
        s="S1-E4"
        link="https://drive.google.com/open?id=1vLD_2syS5JzGIGspD6f7lhnMJw5adLLk&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Kaiju No. 8"
        s="S1-E5"
        link="https://drive.google.com/open?id=1DCcCdTp1Fx8zeRaINC9KLkMJ4tlf_pqb&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Kaiju No. 8"
        s="S1-E6"
        link="https://drive.google.com/open?id=10s06BxdDzvYU4QWNeDMi-tYkJznwn0bZ&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Kaiju No. 8"
        s="S1-E7"
        link="https://drive.google.com/open?id=1W8Fr6yaC3VGMyErVCFwttoXbLPyTBhXm&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Kaiju No. 8"
        s="S1-E8"
        link="https://drive.google.com/open?id=1W8Fr6yaC3VGMyErVCFwttoXbLPyTBhXm&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Kaiju No. 8"
        s="S1-E9"
        link="https://drive.google.com/open?id=1glWkuxAYirOa_AZgFpuchz_MHPIrhUtT&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Kaiju No. 8"
        s="S1-E10"
        link="https://drive.google.com/open?id=19_NTazp0ityqJbN2Ywa09HImXf4bCt2l&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Kaiju No. 8"
        s="S1-E11"
        link="https://drive.google.com/open?id=1H4wb9pr1uUINRGXNx_hVOIzNCvL-TaQG&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Kaiju No. 8"
        s="S1-E12"
        link="https://drive.google.com/open?id=10xYBVbHBg2nxNcaj5KPZz9aYQz2n2KhT&usp=drive_copy"
      />

      <Animeover />
      <Footer />
    </>
  );
}
