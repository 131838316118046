import React from "react";

export default function No() {
  return (
    <>
      <div className="flex justify-center mt-5 ">
        <div className="ml-40 h-20 w-1.2 pl-2 pr-2 flex justify-center items-center backdrop-blur rounded-t-lgdis border rounded border-whitegood shadow shadow-black">
          <h3 className="text-white font-bold text-base">
            Notice!! Some Anime Are not coming In (FHD) For Streaming So plz
            Download The Anime for Good Quietly
          </h3>
        </div>
      </div>
    </>
  );
}
