import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
import ScrollToTop from "../components/ScrollToTop";
export default function Viral() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/dQgzQKXK/1284207.jpg"
        name="Viral Hit"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Viral Hit"
        s="S1-E1"
        link="https://drive.google.com/open?id=1LW3I_t5Joibe1aUSah-R0ia4IMTgP9nH&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Viral Hit"
        s="S1-E2"
        link="https://drive.google.com/open?id=1mWCe9tmw9cpYk3dxplW0TRtDholj6GxD&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Viral Hit"
        s="S1-E3"
        link="https://drive.google.com/file/d/1j8fzhZtOcWis2Vbvc0gokR7YjsM42fmX/view?usp=sharing"
      />
      <Ep
        ep="S1-E4 Viral Hit"
        s="S1-E4"
        link="https://drive.google.com/open?id=1dPw1rQzr3ZmVKNMZ3pHlItv4HCsJqZ9P&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Viral Hit"
        s="S1-E5"
        link="https://drive.google.com/open?id=1ROA1dT2PcNsJp24LxBgl0LPkMkpfqhLO&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Viral Hit"
        s="S1-E6"
        link="https://drive.google.com/open?id=1rM0U9HlllOaKKgPjYjfEqB4O79-TGTl6&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Viral Hit"
        s="S1-E7"
        link="https://drive.google.com/open?id=1mAWQs2XOv20kgTOVrCXPLPjjxozGEPST&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Viral Hit"
        s="S1-E8"
        link="https://drive.google.com/open?id=1bHkl_qMs2aGu8RPm7qONYbfhR2RkTk4n&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Viral Hit"
        s="S1-E9"
        link="https://drive.google.com/open?id=1vuZeBHxk4r5ocIZPd3avsNwpZTPZV60G&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Viral Hit"
        s="S1-E10"
        link="https://drive.google.com/open?id=14CCh1Wo5ZRqN93JvfjV0RqVJlKO2UFeN&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Viral Hit"
        s="S1-E11"
        link="https://drive.google.com/open?id=1jlhiilkfEn7VE_apio-rOXb5kEyYJX4E&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Viral Hit"
        s="S1-E12"
        link="https://drive.google.com/open?id=1cr74ej0ErcNbQnltmHLWfvkxoe8-aKTM&usp=drive_copy"
      />
      <Complete />
      <Footer />
    </>
  );
}
