import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Complete from "../Structure/Complete";

export default function Assassintionclasss1() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/vBJmH649/images.jpg"
        name="Assassination Classroom"
        s="1"
        e="22"
      />

      <Ep
        ep="S1-E1 Assassination Classroom "
        s="S1-E1"
        link="https://drive.google.com/open?id=1DmOi0e6cbsTIORuHvQFNbC-UWiV-EwTr&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Assassination Classroom "
        s="S1-E2"
        link="https://drive.google.com/open?id=1XV3iqCqOM4HSZugLI5-nLF8lZbTVPG1R&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Assassination Classroom"
        s="S1-E3"
        link="https://drive.google.com/open?id=1-u5UOKZewfIVirwrN16MjpQOP8A3xArD&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Assassination Classroom"
        s="S1-E4"
        link="https://drive.google.com/open?id=15fBqCXuw2vLwj1irw7pRfASW9UaHE7Sl&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Assassination Classroom"
        s="S1-E5"
        link="https://drive.google.com/open?id=1dDCmGYI-JC0-maKviUBChn0qx_5FAPCS&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Assassination Classroom"
        s="S1-E6"
        link="https://drive.google.com/open?id=1-phsXC6OmAswumF5aBnw_Hlk4zSwZxCm&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Assassination Classroom"
        s="S1-E7"
        link="https://drive.google.com/open?id=1nN8zWvZ6mKg4K8nypEnmrh_Q5Vj9Vp98&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Assassination Classroom"
        s="S1-E8"
        link="https://drive.google.com/open?id=1HXcSQTFIVNzLL9fAkrpHQotc5K6xpDtt&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Assassination Classroom"
        s="S1-E9"
        link="https://drive.google.com/open?id=1Pc1yddm3WkOFBHboF1b6_jnkufEFX2ni&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Assassination Classroom"
        s="S1-E10"
        link="https://drive.google.com/open?id=17Mx_mHQwPRCYxbxK3M-89tOScF38svL_&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Assassination Classroom"
        s="S1-E11"
        link="https://drive.google.com/open?id=1g32JZHTmNfl_tJsFWB35_h05QEptnjn8&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Assassination Classroom"
        s="S1-E12"
        link="https://drive.google.com/open?id=1HH6UFLvCc0Q638KO84GlQGi0odssdJ9h&usp=drive_copy"
      />
      <Ep
        ep="S1-E13 Assassination Classroom"
        s="S1-E13"
        link="https://drive.google.com/open?id=1cXT_caYY2iK0xfNUulIhyBV7eFwqh6vG&usp=drive_copy"
      />
      <Ep
        ep="S1-E14 Assassination Classroom"
        s="S1-E14"
        link="https://drive.google.com/open?id=1PfHYsTkTHutkVk67ARwQK9QDzSoDGEyu&usp=drive_copy"
      />
      <Ep
        ep="S1-E15 Assassination Classroom"
        s="S1-E15"
        link="https://drive.google.com/open?id=1FJF4bXpDtRRGhkhrk3JBWgbsET6o2uKZ&usp=drive_copy"
      />
      <Ep
        ep="S1-E16 Assassination Classroom"
        s="S1-E16"
        link="https://drive.google.com/open?id=1NvhmdkSbpHvkeo91LFCIMlin7KdPLG4V&usp=drive_copy"
      />
      <Ep
        ep="S1-E17 Assassination Classroom"
        s="S1-E17"
        link="https://drive.google.com/open?id=1cPsrVZeCFDwZUmmhwt4hLiUVCJ7qHn7b&usp=drive_copy"
      />
      <Ep
        ep="S1-E18 Assassination Classroom"
        s="S1-E18"
        link="https://drive.google.com/open?id=1CuOREhR6ERIteko5ncwOV1B5n7CpzKS-&usp=drive_copy"
      />
      <Ep
        ep="S1-E19 Assassination Classroom"
        s="S1-E19"
        link="https://drive.google.com/open?id=1gopww4hLJ6qAkVHmlau75qXgPrwXgStr&usp=drive_copy"
      />
      <Ep
        ep="S1-E20 Assassination Classroom"
        s="S1-E20"
        link="https://drive.google.com/open?id=1kuSZ74WZ4Uc-w7bN5kiRhO1xSP2so2bx&usp=drive_copy"
      />
      <Ep
        ep="S1-E21 Assassination Classroom"
        s="S1-E21"
        link="https://drive.google.com/open?id=1bVyY_O_wchtk6896Hnnn_rM4dU6QPAYI&usp=drive_copy"
      />
      <Ep
        ep="S1-E22 Assassination Classroom"
        s="S1-E22"
        link="https://drive.google.com/open?id=1-_Y6_Fpa12GVMo8fGj11O2a9tLJ6UrdW&usp=drive_copy"
      />
      <Complete />
      <Footer />
    </>
  );
}
