import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Nolonger() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/cH5nSCkw/season-1.jpg"
        name="No Longer Allowed in Another World"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 No Longer Allowed in Another World"
        s="S1-E1"
        link="https://drive.google.com/open?id=1IUE3Z8s4xqN1n0sNqELP0C2wplreyL8z&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 No Longer Allowed in Another World"
        s="S1-E2"
        link="https://drive.google.com/open?id=1eyRM81UBRZtYw5OmS5PIs5JQYQewAuGv&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 No Longer Allowed in Another World"
        s="S1-E3"
        link="https://drive.google.com/open?id=1sP21f9QHDxwcdHqQvfTvdpXo4s_j8in0&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 No Longer Allowed in Another World"
        s="S1-E4"
        link="https://drive.google.com/open?id=1VXSe1Parl_MQiv8aMzyL_6FIETp5VzR4&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 No Longer Allowed in Another World"
        s="S1-E5"
        link="https://drive.google.com/open?id=1pfj3HSS5NbY7J3SCPe8RcmpMtfKazLDx&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 No Longer Allowed in Another World"
        s="S1-E6"
        link="https://drive.google.com/open?id=1QEGcQ7UHweMFRswqbL6HXJccaq8A5Nvb&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 No Longer Allowed in Another World"
        s="S1-E7"
        link="https://drive.google.com/file/d/1ACciAct-ZEYllbRkk5G-egZPEZ8oCX4i/view?usp=sharing"
      />
      <Ep
        ep="S1-E8 No Longer Allowed in Another World"
        s="S1-E8"
        link="https://drive.google.com/file/d/1pizAQxD04_aPhdZSaegD4n0BDFfBJ6h4/view?usp=sharing"
      />
      {/*  <Ep ep="S1-E9 No Longer Allowed in Another World" s="S1-E9" link=""/>
      <Ep ep="S1-E10 No Longer Allowed in Another World" s="S1-E10" link=""/>
      <Ep ep="S1-E11 No Longer Allowed in Another World" s="S1-E11" link=""/>
      <Ep ep="S1-E12 No Longer Allowed in Another World" s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  );
}
