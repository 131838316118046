import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
import ScrollToTop from "../components/ScrollToTop";

export default function Mash() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://a.storyblok.com/f/178900/849x1200/3ea3addb7a/mashle-s2-kv.jpeg/m/filters:quality(95)format(webp)"
        name="Mashle : Magic and Muscles "
        s="2"
        e="12"
      />

      <Ep
        ep="S1-E1 Mashle : Magic and Muscles"
        s="S1-E1"
        link="https://drive.google.com/open?id=1SLmk2kmEW2p0woj0U-T6kaovOA6n0J-b&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Mashle : Magic and Muscles"
        s="S1-E2"
        link="https://drive.google.com/open?id=1gRyT4hRf6vOsBNOFLIJANBAXcdVcD_sx&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Mashle : Magic and Muscles"
        s="S1-E3"
        link="https://drive.google.com/open?id=1MEfro-4ugSl3jsIGfqhOAZXcValsHsBg&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Mashle : Magic and Muscles"
        s="S1-E4"
        link="https://drive.google.com/open?id=1RKQAWCg2_b8OIFANH89vimgtBYIf8khX&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Mashle : Magic and Muscles"
        s="S1-E5"
        link="https://drive.google.com/open?id=1MUf_UiZ4_wZHBhhC-bphYjnf1goOhh4Y&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Mashle : Magic and Muscles"
        s="S1-E6"
        link="https://drive.google.com/open?id=136HFw1oTB9pLD9qA3GUtNljKTfOTOzwC&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Mashle : Magic and Muscles"
        s="S1-E7"
        link="https://drive.google.com/open?id=1ZA3eOwyDo86VGuKQivB6_FZ4wkJGeZ2O&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Mashle : Magic and Muscles"
        s="S1-E8"
        link="https://drive.google.com/open?id=1dKOp6ltOMAJomiVelsAyh2ATrscNGhEd&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Mashle : Magic and Muscles"
        s="S1-E9"
        link="https://drive.google.com/open?id=1dWyz43lhSQHGLYuoByDCqmhs0MCLbTPM&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Mashle : Magic and Muscles"
        s="S1-E10"
        link="https://drive.google.com/open?id=11UzV_nUvXbYZXfO9DKHxHqrX-lmqK-zX&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Mashle : Magic and Muscles"
        s="S1-E11"
        link="https://drive.google.com/open?id=1qdZY_ctbYfRbvNfgD6X5ne6cCIFDCipf&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Mashle : Magic and Muscles"
        s="S1-E12"
        link="https://drive.google.com/open?id=1xM1scXOwDXIgiuq1wQ3rnohLRRnCDrno&usp=drive_copy"
      />
      <Complete />
      <Footer />
    </>
  );
}
