import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Vtuberlegend() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/t42FX9Tq/OIP.jpg"
        name="VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E1"
        link="https://drive.google.com/open?id=1vZO7BdK_I1YkJLhszo5TUSn4IJG5CGEE&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E2"
        link="https://drive.google.com/open?id=119aVSo8IqNNwAYUGMuGlZhyEaL_xjaSj&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E3"
        link="https://drive.google.com/open?id=119aVSo8IqNNwAYUGMuGlZhyEaL_xjaSj&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E4"
        link="https://drive.google.com/open?id=1dU19iC4jeDCs12R7VucKG_KVShZLd7EJ&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E5"
        link="https://drive.google.com/open?id=1SuGZkYtaEIUIlx3UiSYoB5NFVHhsUFJl&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E6"
        link="https://drive.google.com/file/d/1sDUV8wXPpOdPOcp-EfOPKXXcpB7CTUnx/view?usp=drive_link"
      />
      <Ep
        ep="S1-E7 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E7"
        link="https://drive.google.com/file/d/1pPTE4BoWRZ-_jzIQeKbzUrPe9DKGY06A/view?usp=drive_link"
      />
      <Ep
        ep="S1-E8 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream"
        s="S1-E8"
        link="https://drive.google.com/file/d/1Nadzx8hVVYgXBAcXVzf_mxFo-je_yVbe/view?usp=sharing"
      />
      {/*<Ep ep="S1-E9 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream" s="S1-E9" link=""/>
      <Ep ep="S1-E10 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream" s="S1-E10" link=""/>
      <Ep ep="S1-E11 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream" s="S1-E11" link=""/>
      <Ep ep="S1-E12 VTuber Legend: How I Went Viral After Forgetting to Turn Off My Stream" s="S1-E12" link=""/>
      <Animeover /> */}
      <Footer />
    </>
  );
}
