import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";

export default function Jjk2() {
  return (
    <>
      <Main
        img="https://i.postimg.cc/QCHTNWmg/MV5-BNGY4-MTg3-Nzgt-Mm-Fk-Zi00-NTg5-LWEx-Mm-Et-MWI3-Yz-I1-ODdm-MWQ1-Xk-Ey-Xk-Fqc-Gde-QXVy-Mj-Qw-MDg0-Ng-V1-FMjpg-UX1000.jpg"
        name="Jujutsu kaisen"
        s="1"
        e="24"
      />

      <Ep ep="S1-E1 Jujutsu kaisen " s="S1-E1" link="https://drive.google.com/open?id=1tcke6lDVdy01mOJBcJSAPHbuySGPMnXQ&usp=drive_copy" />
      <Ep ep="S1-E2 Jujutsu kaisen " s="S1-E2" link="https://drive.google.com/open?id=1dbDvGgiM1iGSp-SeNf3jrSzOLmC7sUAt&usp=drive_copy" />
      <Ep ep="S1-E3 Jujutsu kaisen " s="S1-E3" link="https://drive.google.com/open?id=1uS7bm988L6aW_6QzTmGLihxbrCbWuthE&usp=drive_copy" />
      <Ep ep="S1-E4 Jujutsu kaisen " s="S1-E4" link="https://drive.google.com/open?id=1tz9eHKOycnRokiiZWvSXm55dHEKYgwF-&usp=drive_copy" />
      <Ep ep="S1-E5 Jujutsu kaisen " s="S1-E5" link="https://drive.google.com/open?id=1sFi7QGZvfplXAYum9zKlSxrhvhAnAzZw&usp=drive_copy" />
      <Ep ep="S1-E6 Jujutsu kaisen " s="S1-E6" link="https://drive.google.com/open?id=1AXjEcUvmSS-Qn2YNMqwM_S3Q2H4sUdd4&usp=drive_copy" />
      <Ep ep="S1-E7 Jujutsu kaisen " s="S1-E7" link="https://drive.google.com/open?id=1Tsgx_zuuNZUPntXSnnIt6tBPgXtuSUeQ&usp=drive_copy" />
      <Ep ep="S1-E8 Jujutsu kaisen " s="S1-E8" link="https://drive.google.com/open?id=1YYCrwYTorleKhN6dEpyUxckF7wI-VyL_&usp=drive_copy" />
      <Ep ep="S1-E9 Jujutsu kaisen " s="S1-E9" link="https://drive.google.com/open?id=1Afer8O70YZEhzUKJQUDUGCkx__fo2qD9&usp=drive_copy" />
      <Ep ep="S1-E10 Jujutsu kaisen " s="S1-E10" link="https://drive.google.com/open?id=1LFZAV2Wjh2xtvyRuBZ4dR64KM7Ff7DBk&usp=drive_copy" />
      <Ep ep="S1-E11 Jujutsu kaisen " s="S1-E11" link="https://drive.google.com/open?id=1r_vh0eAeFn16qUWtdW4ueYSmviKkpizk&usp=drive_copy" />
      <Ep ep="S1-E12 Jujutsu kaisen " s="S1-E12" link="https://drive.google.com/open?id=1JHI67KDVoffNWR2vBZHtiIC5fo4al6Xp&usp=drive_copy" />
      <Ep ep="S1-E13 jujutsu kaisen" s="S1-E13" link="https://drive.google.com/open?id=1Ssojz6o1xacdgoPrVR-e1MTd0aOvwtLV&usp=drive_copy" />
      <Ep ep="S1-E14 jujutsu kaisen" s="S1-E14" link="https://drive.google.com/open?id=153TJ6NI0cr8Tbf33PClSHBlcbqCzjXGB&usp=drive_copy" />
      <Ep ep="S1-E15 jujutsu kaisen" s="S1-E15" link="https://drive.google.com/open?id=1Xa2-6u-sAHHlQqpqT8o9UObRQ2Pzo6hC&usp=drive_copy" />
      <Ep ep="S1-E16 jujutsu kaisen" s="S1-E16" link="https://drive.google.com/open?id=1enbYN4-e-EXMziTe5GXAAO9DFJrpui6m&usp=drive_copy" />
      <Ep ep="S1-E17 jujutsu kaisen" s="S1-E17" link="https://drive.google.com/open?id=1jYz4FVvdiVSHquzV_KKdeaWeBEA9D5JA&usp=drive_copy" />
      <Ep ep="S1-E18 jujutsu kaisen" s="S1-E18" link="https://drive.google.com/open?id=1rW1slnmXhFQcoBf1LzKTeJNcU3XiZOZy&usp=drive_copy" />
      <Ep ep="S1-E19 jujutsu kaisen" s="S1-E19" link="https://drive.google.com/open?id=18Kn5JNbXXrGBrd2zK4ygLYKbqIJ0uZbY&usp=drive_copy" />
      <Ep ep="S1-E20 jujutsu kaisen" s="S1-E20" link="https://drive.google.com/open?id=1V1BV22hVo3tHX1-NqMNlhUekMiRS-wkx&usp=drive_copy" />
      <Ep ep="S1-E21 jujutsu kaisen" s="S1-E21" link="https://drive.google.com/open?id=1DJnmdMNdDcV5n5kJ9_bmJDRuLgX8e90F&usp=drive_copy" />
      <Ep ep="S1-E22 jujutsu kaisen" s="S1-E22" link="https://drive.google.com/open?id=1lt2XjT6Pp1jD4ekHhKiNQ2H8vJQ1vOig&usp=drive_copy" />
      <Ep ep="S1-E23 jujutsu kaisen" s="S1-E23" link="https://drive.google.com/open?id=1mtyVGrL6-OE1LwisMRRz4a-q_L7MrnV8&usp=drive_copy" />
      <Ep ep="S1-E24 jujutsu kaisen" s="S1-E24" link="https://drive.google.com/open?id=18AYyCPB9Ye_fAxaXuNfDsESTktjhkO4U&usp=drive_copy"/>
      <Complete/>
      <Footer />
    </>
  );
}
