import React from "react";

export default function Tell() {
  return (
    <>
      <div className="flex justify-center mt-10 mb-10">
        <div className="h-20 w-auto pl-2 pr-2 flex justify-center items-center backdrop-blur rounded-t-lgdis border rounded border-whitegood shadow shadow-black">
          {/* <h3 className='text-white font-bold text-base'>Notice!! Some Anime Are not coming In (FHD) For Streaming So plz Download The Anime for Good Quietly</h3> */}
          <img
            // src="https://i.postimg.cc/85mbTpP9/share.gif"
            src="https://hianime.to/images/share-icon.gif"
            alt="Share Hin-Anime website"
            className="h-16 w-auto rounded-full mr-1"
          />
          <p className="w-32 text-center mr-3 text-white">
            Share Hin-Anime to your friends
          </p>
          <div className="sharethis-inline-share-buttons"></div>
        </div>
      </div>
    </>
  );
}
