import React from "react";

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Demon4() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/tC7XVwSq/demon-slayer-s4-poster-1714500560283.jpg"
        name="Demon Slayer"
        s="4"
        e="11"
      />

      <Ep
        ep="S4-E1 Demon Slayer"
        s="S4-E1"
        link="https://drive.google.com/open?id=1xJwBcBFERPikV_WEiTDN6CPqc0EhFb79&usp=drive_copy"
      />
      <Ep
        ep="S4-E2 Demon Slayer"
        s="S4-E2"
        link="https://drive.google.com/open?id=1oJUu-PU9PksPjvVMDeRXISrcTuHYtgy_&usp=drive_copy"
      />
      <Ep
        ep="S4-E3 Demon Slayer"
        s="S4-E3"
        link="https://drive.google.com/open?id=1MMbJFwcFgC-uYOlwnxijmu1H2VBNToNH&usp=drive_copy"
      />
      <Ep
        ep="S4-E4 Demon Slayer"
        s="S4-E4"
        link="https://drive.google.com/open?id=1_EyBGY8HgJh2utmeqXFcdG4gomBUHgf4&usp=drive_copy"
      />
      <Ep
        ep="S4-E5 Demon Slayer"
        s="S4-E5"
        link="https://drive.google.com/open?id=1lNnKnA7Dpxl0tYQzEQNQ9HCkAxjDGJ6x&usp=drive_copy"
      />
      <Ep
        ep="S4-E6 Demon Slayer"
        s="S4-E6"
        link="https://drive.google.com/open?id=1AzTg3o6VqkcBhU5tlWEbBzxOEdfmj_jv&usp=drive_copy"
      />
      <Ep
        ep="S4-E7 Demon Slayer"
        s="S4-E7"
        link="https://drive.google.com/open?id=15i51DO-5gQWoum5wXrRapoSNGEfHtahH&usp=drive_copy"
      />
      <Ep
        ep="S4-E8 Demon Slayer"
        s="S4-E8"
        link="https://drive.google.com/open?id=1wthEUUJBUPpe5Ipa42ca678IRWb-4KGu&usp=drive_copy"
      />
      <Animeover />
      <Footer />
    </>
  );
}
