import React from "react";

import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Chanso() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/vBHvQSkp/Chainsawman-2.jpg"
        name="Chainsaw Man"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Chainsaw Man"
        s="S1-E1"
        link="https://drive.google.com/open?id=1f4KRYYJfUphYrUFm6o31eKaWt0M0RSgB&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Chainsaw Man"
        s="S1-E2"
        link="https://drive.google.com/open?id=1fibY5bFdRKtqi9MvnGz41p5W4rQiv1UD&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Chainsaw Man"
        s="S1-E3"
        link="https://drive.google.com/open?id=11UCUzRZVHUOxIed5Qu6elhJngRCnW7A6&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Chainsaw Man"
        s="S1-E4"
        link="https://drive.google.com/open?id=1kaiJ5zjQBQd4earXTdxF1qjGSdBfMeTj&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Chainsaw Man"
        s="S1-E5"
        link="https://drive.google.com/open?id=1DDPvMvte_TohqznjYTcMqNQ4Dh6JXY56&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Chainsaw Man"
        s="S1-E6"
        link="https://drive.google.com/open?id=17fK7a0_ZO1mUnJAgcMM0sHfksutW3ebD&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Chainsaw Man"
        s="S1-E7"
        link="https://drive.google.com/open?id=1JHNRwjIewuM7Qzo0Gizohf-31eZ2aydX&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Chainsaw Man"
        s="S1-E8"
        link="https://drive.google.com/open?id=1s9xGlrTAEum43KfTeYAFXeg1xRvBwCus&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Chainsaw Man"
        s="S1-E9"
        link="https://drive.google.com/open?id=1cfXkGgcjAggBjb3DmbMSSlkBg8yq0MuB&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Chainsaw Man"
        s="S1-E10"
        link="https://drive.google.com/open?id=1eCU9cL62GW-EqMxVuR00eVneVoBRDnXx&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Chainsaw Man"
        s="S1-E11"
        link="https://drive.google.com/open?id=1w38TOfcsURZAy79joIRJxVUEoU2VWQRJ&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Chainsaw Man"
        s="S1-E12"
        link="https://drive.google.com/open?id=1z11s1uDDFY32kdsF6-T_LyqEkho4b0zb&usp=drive_copy"
      />

      <Animeover />
      <Footer />
    </>
  );
}
