import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Turebeauty() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/mDKWRkmv/true-beauty-snwt-960.webp"
        name="True Beauty"
        s="1"
        e="4"
      />

      <Ep
        ep="S1-E1 True Beauty"
        s="S1-E1"
        link="https://drive.google.com/open?id=1AjCkbr8jRnJLt2u39ewjLfyaqqjCVsmc&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 True Beauty"
        s="S1-E2"
        link="https://drive.google.com/open?id=1DwU-gsLHxeMS2JG6Ic8x4EaqUcy-bpHh&usp=drive_copy"
      />
      <Ep ep="S1-E3 True Beauty" s="S1-E3" link="" />
      {/* <Ep ep="S1-E4 True Beauty" s="S1-E4" link=""/>
      <Ep ep="S1-E5 True Beauty" s="S1-E5" link=""/>
      <Ep ep="S1-E6 True Beauty" s="S1-E6" link=""/>
      <Ep ep="S1-E7 True Beauty" s="S1-E7" link=""/>
      <Ep ep="S1-E8 True Beauty" s="S1-E8" link=""/>
      <Ep ep="S1-E9 True Beauty" s="S1-E9" link=""/>
      <Ep ep="S1-E10 True Beauty" s="S1-E10" link=""/>
      <Ep ep="S1-E11 True Beauty" s="S1-E11" link=""/>
      <Ep ep="S1-E12 True Beauty" s="S1-E12" link=""/> */}
      <Animeover />
      <Footer />
    </>
  );
}
