import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Makeine() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/wjT05fRB/OIP-1.jpg"
        name="Makeine: Too Many Losing Heroines"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Makeine: Too Many Losing Heroines"
        s="S1-E1"
        link="https://drive.google.com/open?id=1dTUmVEIQZzAx2gAR1_aGd0s3EzTlGCeD&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Makeine: Too Many Losing Heroines"
        s="S1-E2"
        link="https://drive.google.com/open?id=14vgzlgpxC3ldLp7UGr-lvKvrmPv1r70y&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Makeine: Too Many Losing Heroines"
        s="S1-E3"
        link="https://drive.google.com/open?id=14gske_VYxZ07t3rQAIuO0V6uGx7Da7ts&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Makeine: Too Many Losing Heroines"
        s="S1-E4"
        link="https://drive.google.com/open?id=1jWIIBH4LHt-tkxQcJZMZulI8TQXPNJ2X&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Makeine: Too Many Losing Heroines"
        s="S1-E5"
        link="https://drive.google.com/open?id=1UTUqy9-HxLr6h36PDnlPmKWi8C821rVd&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Makeine: Too Many Losing Heroines"
        s="S1-E6"
        link="https://drive.google.com/open?id=1GFlKnL7dzbKONnbGNJC7sGFURTLjPg7U&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Makeine: Too Many Losing Heroines"
        s="S1-E7"
        link="https://drive.google.com/open?id=1kUH0afc9DRSGrPNH2kSAzWTHEbECSVqf&usp=drive_copy"
      />
      {/* <Ep ep="S1-E8 Makeine: Too Many Losing Heroines" s="S1-E8" link=""/>
      <Ep ep="S1-E9 Makeine: Too Many Losing Heroines" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Makeine: Too Many Losing Heroines" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Makeine: Too Many Losing Heroines" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Makeine: Too Many Losing Heroines" s="S1-E12" link=""/>
      <Animeover /> */}
      <Footer />
    </>
  );
}
