import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaDiscord,
  FaHome,
  FaInfoCircle,
  FaFilm,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const Navbars = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to change wallpaper based on screen size
  const changeWallpaper = (urlMobile, urlDesktop) => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const selectedWallpaper = isMobile ? urlMobile : urlDesktop;
    document.body.style.backgroundImage = `url('${selectedWallpaper}')`;
  };

  // Toggle the sidebar
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // Close navbar on screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* Menu Icon for Mobile */}
      <div className="md:hidden fixed top-5 left-4 z-20">
        <button onClick={toggleNavbar} className="text-white">
          {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
        </button>
      </div>

      {/* Sidebar Navigation */}
      <header
        className={` w-40 fixed z-10 transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
      >
        <div className="mt-4 ml-10">
          <Link to="/home">
            <img
              src="https://i.postimg.cc/qMxmwQJ4/logo-of-hin-anime.png"
              className="h-16 w-auto relative z-20"
              alt="Logo"
            />
          </Link>
        </div>
        <nav
          className={`backdrop-blur bg-whitelite border border-whitegood shadow shadow-black h-screen  w-full md:w-44 fixed top-0 left-0 flex flex-col items-start pt-20 pl-6 text-white space-y-4 ${
            isOpen && "overflow-y-auto md:overflow-y-hidden"
          }`}
        >
          {/* Navigation Links */}
          <ul className="space-y-5 w-full">
            <li>
              <Link
                to="/home"
                className="flex items-center space-x-3 py-2 rounded hover:text-blue-400"
              >
                <FaHome className="text-xl" />
                <span>HOME</span>
              </Link>
            </li>
            <li>
              <Link
                to="/movies"
                className="flex items-center space-x-3 py-2 rounded hover:text-blue-400"
              >
                <FaFilm className="text-xl" />
                <span>MOVIES</span>
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="flex items-center space-x-3 py-2 rounded hover:text-blue-400"
              >
                <FaInfoCircle className="text-xl" />
                <span>ABOUT</span>
              </Link>
            </li>

            <li>
              <a
                href="https://discord.gg/2JBnqk2kne"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-3 py-2 w-full rounded hover:text-blue-400"
              >
                <FaDiscord className="text-xl" />
                <span>DISCORD</span>
              </a>
            </li>
          </ul>

          <h2>Themes :-</h2>

          {/* Wallpaper Links */}
          <div className="flex flex-col mt-10 space-y-4">
            <WallpaperLink
              label="Jujutsu Kaisen"
              urlMobile="https://i.postimg.cc/HxkVrhMM/1012681.png"
              urlDesktop="https://i.postimg.cc/6p3Pg2rY/jujutsu-kaisen.jpg"
              changeWallpaper={changeWallpaper}
            />
            <WallpaperLink
              label="One Piece"
              urlMobile="https://i.postimg.cc/zB23KFwc/1006847.jpg"
              urlDesktop="https://i.postimg.cc/50ckXsZR/one-piece.jpg"
              changeWallpaper={changeWallpaper}
            />
            <WallpaperLink
              label="Demon Slayer"
              urlMobile="https://i.postimg.cc/G3ZGfbYS/1007935.jpg"
              urlDesktop="https://i.postimg.cc/2yrcwVjd/kyojuro-rengoku.jpg"
              changeWallpaper={changeWallpaper}
            />
            <WallpaperLink
              label="Mountain"
              urlMobile="https://i.postimg.cc/cJ3FGQrM/mountain-mobile.jpg"
              urlDesktop="https://i.postimg.cc/cJ3FGQrM/montai.jpg"
              changeWallpaper={changeWallpaper}
            />
          </div>
        </nav>
      </header>
    </>
  );
};

// Wallpaper Link Component
const WallpaperLink = ({ label, urlMobile, urlDesktop, changeWallpaper }) => (
  <Link
    to="#"
    className="text-white font-semibold text-base py-2 px-2 rounded bg-gray-800 w-32 text-center hover:bg-gray-700 hover:text-blue-400 transition ease-in-out"
    onClick={(e) => {
      e.preventDefault();
      changeWallpaper(urlMobile, urlDesktop);
    }}
  >
    {label}
  </Link>
);

export default Navbars;
