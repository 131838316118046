import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";

import Footer from "../components/Footer";
import Complete from "../Structure/Complete";
import ScrollToTop from "../components/ScrollToTop";
export default function Haikyu() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/02gBR0Mk/1-Rfa9o5-H6-Zl-Xzb-DVNlw4j-ULd-Xpi.jpg"
        name="Haikyu"
        s="2"
        e="12"
      />

      <Ep
        ep="S1-E1 Haikyu"
        s="S1-E1"
        link="https://drive.google.com/open?id=1zHx8ugf2Gebze-Gphb4iJCIvUmL-OKQE&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Haikyu"
        s="S1-E2"
        link="https://drive.google.com/open?id=1DurUF14ivp3RjBRni3xIDZpoRLKviNvo&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Haikyu"
        s="S1-E3"
        link="https://drive.google.com/open?id=1iWh3fVHwnSI0Q5asYWf10-qDPXrvUqvE&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Haikyu"
        s="S1-E4"
        link="https://drive.google.com/open?id=16ZBcLT4_vqlX1Hbt5VYml307GZo6nzOq&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Haikyu"
        s="S1-E5"
        link="https://drive.google.com/open?id=17tXn1PlvZj7FrgfFYBUi4mpzyoY8dBcg&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Haikyu"
        s="S1-E6"
        link="https://drive.google.com/open?id=1idNZobTEljcwyxaPS_c9CHnpQFLCT7_z&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Haikyu"
        s="S1-E7"
        link="https://drive.google.com/open?id=1VeenGSl0wwPmxXAWuF-mZcf95Ajg_4VL&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Haikyu"
        s="S1-E8"
        link="https://drive.google.com/open?id=183A7G3Dv6U2Mofs4bu_WD6kMa8jMwK8N&usp=drive_copy"
      />
      <Ep
        ep="S1-E9 Haikyu"
        s="S1-E9"
        link="https://drive.google.com/open?id=1XduhSKXM72JXA6z2vJX4HcqLE3a1qqDi&usp=drive_copy"
      />
      <Ep
        ep="S1-E10 Haikyu"
        s="S1-E10"
        link="https://drive.google.com/open?id=1Tse-75UM5mBjBao7Ofk71pb3FJ7gTJou&usp=drive_copy"
      />
      <Ep
        ep="S1-E11 Haikyu"
        s="S1-E11"
        link="https://drive.google.com/open?id=1TyKaoOIOQFL8qj1chBnG9iR40LC47r3k&usp=drive_copy"
      />
      <Ep
        ep="S1-E12 Haikyu"
        s="S1-E12"
        link="https://drive.google.com/open?id=1xX9izdtMyIa042K4UxsoGa1RberQF2tl&usp=drive_copy"
      />
      <Ep
        ep="S1-E13 Haikyu"
        s="S1-E13"
        link="https://drive.google.com/open?id=1kB74gBuUuuH4Dj_lEcVAq6VBV95QIQLu&usp=drive_copy"
      />
      <Ep
        ep="S1-E14 Haikyu"
        s="S1-E14"
        link="https://drive.google.com/open?id=1H2VQjVy8p820HLioTTAXGmhPSGBO_u-K&usp=drive_copy"
      />
      <Ep
        ep="S1-E15 Haikyu"
        s="S1-E15"
        link="https://drive.google.com/open?id=10HLWvh_n_u8dM5yg5Eevu1ByOTUYzIeT&usp=drive_copy"
      />
      <Ep
        ep="S1-E16 Haikyu"
        s="S1-E16"
        link="https://drive.google.com/open?id=1ITCXQ8xpiHZjNetS8wVIibKJ2xsYzxr6&usp=drive_copy"
      />
      <Ep
        ep="S1-E17 Haikyu"
        s="S1-E17"
        link="https://drive.google.com/open?id=1e0FnRUkui31yDCR6L1K2nqd7t4DM1RwX&usp=drive_copy"
      />
      <Ep
        ep="S1-E18 Haikyu"
        s="S1-E18"
        link="https://drive.google.com/open?id=13j5c1pvag6uOE_oeWzY4ha1VNiBYluLq&usp=drive_copy"
      />
      <Ep
        ep="S1-E19 Haikyu"
        s="S1-E19"
        link="https://drive.google.com/open?id=1RbAPJrejLlcwCNZDYf4fKfhSHDdNRb_b&usp=drive_copy"
      />
      <Ep
        ep="S1-E20 Haikyu"
        s="S1-E20"
        link="https://drive.google.com/open?id=1s7HjwQzQ0035y8Ob3tDcCsnGKTIkidyo&usp=drive_copy"
      />
      <Ep
        ep="S1-E21 Haikyu"
        s="S1-E21"
        link="https://drive.google.com/open?id=1MZFu6Y6Kut9N8gc08aKlVp75tj-OCy9u&usp=drive_copy"
      />
      <Ep
        ep="S1-E22 Haikyu"
        s="S1-E22"
        link="https://drive.google.com/open?id=1qob2wenV1PWba0X0fmb1l4s_OMEOMbQv&usp=drive_copy"
      />
      <Ep
        ep="S1-E23 Haikyu"
        s="S1-E23"
        link="https://drive.google.com/open?id=1B3z7IoT7YKgxpeU_VciCwKCRhfMj8RyF&usp=drive_copy"
      />
      <Ep
        ep="S1-E24 Haikyu"
        s="S1-E24"
        link="https://drive.google.com/open?id=1fHkAIzfUHpkJCuxWxwNh1fTUnnIYj1Z_&usp=drive_copy"
      />
      <Ep
        ep="S1-E25 Haikyu"
        s="S1-E25"
        link="https://drive.google.com/open?id=1hiqvCMVkrcAkAJb4kPp9_1Y4OseyVfsn&usp=drive_copy"
      />

      <Complete />
      <Footer />
    </>
  );
}
