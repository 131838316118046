import React from "react";
import { Link } from "react-router-dom";

export default function Watch() {
  return (
    <>
      <div className=" -mt-4 flex justify-center">
        <div className="h-120 w-120 flex justify-center items-center flex-col bg-whitelite backdrop-blur rounded-lg border border-whitegood shadow shadow-black">
          <iframe
            src="https://drive.google.com/file/d/18h576uR9NL7RVi0z6seZ0sUpQk90dclU/preview"
            controls
            allowFullScreen
            className="h-130 w-130 rounded-sm"
          ></iframe>
          <div className="">
            <Link to="#">
              <button className="flex items-center bg-gradient-to-r from-gray-800 to-gray-900 text-white font-bold py-2 px-6 rounded-lg shadow-lg hover:shadow-purple-500/60 transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:from-purple-700 hover:to-pink-700">
                <span className="mr-2">Next</span>
                {/* <!-- Right Arrow Icon --> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
