import React from "react";
import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
export default function Dayswithmyst() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://i.postimg.cc/43Nw5KDT/MV5-BZDU2-MWNi-YWMt-Mj-Ix-ZS00-ZTVl-LTkx-ZDAt-Yz-A5-Yz-Fh-ODBh-ODAy-Xk-Ey-Xk-Fqc-Gc-V1-FMjpg-UX1000.jpg"
        name="Days with My Stepsister"
        s="1"
        e="12"
      />

      <Ep
        ep="S1-E1 Days with My Stepsister"
        s="S1-E1"
        link="https://drive.google.com/open?id=1Nxwm8P_7Eb7I5rkXAUTybDTi_Cg54zcB&usp=drive_copy"
      />
      <Ep
        ep="S1-E2 Days with My Stepsister"
        s="S1-E2"
        link="https://drive.google.com/open?id=1JlbcT-aAAbZTJY42KFBSDXFXMD82E6eW&usp=drive_copy"
      />
      <Ep
        ep="S1-E3 Days with My Stepsister"
        s="S1-E3"
        link="https://drive.google.com/open?id=18yeEgZh7CK8XSc57i0xYkublwqWPZzMv&usp=drive_copy"
      />
      <Ep
        ep="S1-E4 Days with My Stepsister"
        s="S1-E4"
        link="https://drive.google.com/open?id=16P91sLMHxUTW8aaDPLlyxa-DlR79N6Ne&usp=drive_copy"
      />
      <Ep
        ep="S1-E5 Days with My Stepsister"
        s="S1-E5"
        link="https://drive.google.com/open?id=1p0rVOt4WOLyayHST-Woz2wMTJb19iqr0&usp=drive_copy"
      />
      <Ep
        ep="S1-E6 Days with My Stepsister"
        s="S1-E6"
        link="https://drive.google.com/open?id=1r9kfwLNpQvYyKYF4zmemn3z2BiuPDgpS&usp=drive_copy"
      />
      <Ep
        ep="S1-E7 Days with My Stepsister"
        s="S1-E7"
        link="https://drive.google.com/open?id=12-xczhEMLM_zfNStimZvXIj4WCE3bwQx&usp=drive_copy"
      />
      <Ep
        ep="S1-E8 Days with My Stepsister"
        s="S1-E8"
        link="https://drive.google.com/file/d/1rILAKK7uzVy-oiAUanEhDLf5VkRkF70W/view?usp=sharing"
      />
      {/* <Ep ep="S1-E9 Days with My Stepsister" s="S1-E9" link=""/>
      <Ep ep="S1-E10 Days with My Stepsister" s="S1-E10" link=""/>
      <Ep ep="S1-E11 Days with My Stepsister" s="S1-E11" link=""/>
      <Ep ep="S1-E12 Days with My Stepsister" s="S1-E12" link=""/>  */}
      <Animeover />
      <Footer />
    </>
  );
}
